<template>
  <div class="page-manager-asset">
    <b-card title="Cấp phát và thu hồi vật dụng">
      <div class="allocation-recall-search">
        <b-row>
          <b-col lg="4" md="4" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select v-model="modelSearch.organizationBranchId" label="organizationBranchName" @input="getListDeivce" :reduce="(department) => department.id" :options="listDepartment"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Tài sản</label>
              <v-select @input="getListAllocationRecall" v-model="modelSearch.deviceId" label="type" :reduce="(type) => type.id" :options="listDeviceAll">
                 <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <div class="acction-search-item">
              <b-button @click="getListDeivce"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col lg="12" md="12" xs="12" sm="12">
          <div class="data-table-asset">
            <span class="custom-span">
              Có tổng số {{totalCount}} bản ghi
            </span>
            <b-table :items="listAllocationRecall"
                     :fields="fieldsItem"
                     :per-page="perPage"
                     :current-page="currentPage"
                     class="mb-0">
              <template #cell(index)="data">
                <span>{{data.index + 1}}</span>
              </template>
              <template #cell(action)="data">
                <b-button @click="updateAllcation(data.item)" v-b-tooltip.hover.top="'Cập nhật'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-success"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button @click="recallAsset(data.item)" v-b-tooltip.hover.top="'Thu hồi'"
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="flat-danger"
                          class="btn-icon rounded-circle"
                >
                  <feather-icon icon="UserMinusIcon" />
                </b-button>
              </template>
            </b-table>
            <div class="pagination-item-table mt-2">
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal ref="modal-recall"
             size="sm"
             hide-footer
             hide-header>
      <div class="title-modal">
        <h4>Thu hồi tài sản</h4>
      </div>
      <div class="body-main">
        <b-form-group>
          <label>Số lượng thu hồi</label>
          <b-form-input></b-form-input>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listStatusAsset: [],
      totalCount: 0,
      modelSearch: {
        deviceType: 0,
        organizationBranchId: null,
        status: null,
        assignStatus: null,
        deviceId: null,
      },
      listAllocationRecall: [],
      listDeviceAll: [],
      perPage: 10,
      currentPage: 1,
      fieldsItem: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'type', label: 'Thiết bị',
        },
        {
          key: 'fullName', label: 'Nhân viên',
        },
        {
          key: 'quantity', label: 'Sl cấp',
        },
        {
          key: 'assignDate', label: 'Ngày cấp', thClass: 'custom-th-date',
        },
        {
          key: 'action', label: 'Hành động', thClass: 'custom-th-action', tdClass: 'custom-td-action',
        },
      ],
    }
  },
  methods: {
    getListDeivce() {
      if (this.modelSearch.organizationBranchId === null) {
        this.modelSearch.deviceId = null
        this.getListAllAssetItem()
        this.getListAllocationRecall()
      }
      this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
        this.listDeviceAll = res.data
        this.getListAllocationRecall()
      })
    },
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListAllocationRecall() {
      this.listAllocationRecall = []
      this.$crm.post('device/find-assigned-device', this.modelSearch).then(res => {
        this.listAllocationRecall = res.data
        this.totalCount = this.listAllocationRecall.length
        for (let i = 0; i < this.listAllocationRecall.length; i++) {
          this.listAllocationRecall[i].assignDate = moment(this.listAllocationRecall[i].assignDate).format('DD-MM-YYYY')
        }
      })
    },
    getListAllAssetItem() {
      this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
        this.listDeviceAll = res.data
      })
    },
    updateAllcation(item) {
      this.$router.push({
        name: 'update-allocation-asset',
        query: {
          idAllocation: item.id,
        },
      })
    },
    recallAsset(item) {
      this.$swal({
        title: 'Thu hồi tài sản',
        text: `Bạn có muốn thu hồi tài sản ${item.type} từ nhân viên ${item.fullName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`device/revoke/${item.id}`).then(res => {
            this.getListAllocationRecall()
            this.$swal({
              icon: 'success',
              title: 'Thu hồi thành công!',
              text: `Thu hồi tài sản ${item.type} từ nhân viên ${item.fullName} thành công`,
              showConfirmButton: false,
              timer: 1500,
            })
            this.$router.push({ name: 'history-asset' })
          })
        }
      })
    },
  },
  created() {
    this.getListDepartment()
    this.getListAllocationRecall()
    if (this.modelSearch.organizationBranchId === null) {
      this.getListDeivce()
    }
  },
  computed: {
    rows() {
      return this.listAllocationRecall.length
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/manager-item.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';
</style>
